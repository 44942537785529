import React, {useEffect, useState} from 'react';
import './App.css';
import MatchComponent from './components/MatchComponent';
import Loading from './components/Loading';
import axios from 'axios';

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect( () => {
     async function fetchData() {

       const raw = JSON.stringify({"start_date":"2020-01-01","end_date":"2020-05-18"});

       const conversions = await axios.post("https://us-central1-my-project-cajo.cloudfunctions.net/function-2").then((response) => {
           return response.data;
       }, e => {
           console.log(e);
           return [];
       })
         .catch(error => {
           console.log('error', error);
           setLoading(false);
         });
       const candidates = await axios.get('https://us-central1-my-project-cajo.cloudfunctions.net/function-3').then(
         (response) => {
           return response.data;
         }, e => {
           console.log(e);
           setLoading(false);
           return [];
         });
       const matches = matchConversionsWithApplicants(conversions, candidates);
       setData(matches);
       setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="App">
      {!loading &&
        <MatchComponent match={data}/>
      }
      {loading &&
        <Loading/>
      }
    </div>
  );
}

function matchConversionsWithApplicants(conversions, applicants) {
  const matches = [];
  conversions.forEach((conversion) => {
    conversion.converted_at = new Date(Number(conversion.year), Number(conversion.month) - 1, Number(conversion.day), Number(conversion.hour), Number(conversion.minute))
    const applicant = applicants.find((applicant) => {
      applicant.applied_at = new Date(applicant.created_at);
      return timeMatch(conversion.converted_at, applicant.applied_at);
    });
    matches.push({conversion, applicant});
  });
  applicants.forEach((applicant) => {
    applicant.applied_at = new Date(applicant.created_at);
    if(!matches.some((match) => {
      return match.applicant ? applicant.candidate_id === match.applicant.candidate_id : false;
    })) {
      matches.push({applicant});
    }
  });
  matches.sort((a,b) => {
    const timeA = a.conversion ? a.conversion.converted_at : a.applicant.applied_at;
    const timeB = b.conversion ? b.conversion.converted_at : b.applicant.applied_at;
    return timeA < timeB ? -1 : 1;
  });
  return matches;
}

function timeMatch(timeA, timeB) {
  const diffInMilliseconds = Math.abs(timeA - timeB);
  const diffInMinutes = Math.floor((diffInMilliseconds/1000)/60);
  return diffInMinutes < 3;
}

export default App;
