import React from 'react';
import ga from './ga.png'
import sr from './sr.png'
function MatchComponent({match}) {
  return (
    <div>
      <div className="match match-header">
        <div className="conversion-container">
          <img src={ga} alt=""/>
        </div>
        <div className="candidate-container">
          <img src={sr} alt=""/>
        </div>
        <div className="match-container">

        </div>
      </div>
      {match.map((matchItem, i) => {
        return (
        <div key={i} className="match">
          <div className="conversion-container">
          {matchItem.conversion && <div className="conversion">
            <div className="client-id">
              { matchItem.conversion.clientId }
            </div>
            <div className="source">
              { matchItem.conversion.source }
            </div>
            <div className="campaign-medium">
              <div className="campaign">
                { matchItem.conversion.campaign },
              </div>
              <div className="medium">
                { matchItem.conversion.medium }
              </div>
            </div>

            <div className="converted-at">
              { matchItem.conversion.converted_at.toUTCString() }
            </div>
        </div>}
          </div>
          <div className="candidate-container">
            {matchItem.applicant && <div className="candidate">
              <div className="candidate-id">
                { matchItem.applicant.candidate_id }
              </div>
              <div className="status">
                { matchItem.applicant.status }
              </div>
              <div className="applied-at">
                { matchItem.applicant.applied_at.toUTCString() }
              </div>
            </div>}
          </div>
          <div className="match-container">
            {matchItem.conversion && matchItem.applicant &&
            <button>Match</button>}
          </div>
        </div>
        )})}
    </div>
  );
}

export default MatchComponent
